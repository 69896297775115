<template>
  <form @submit.prevent="sendForm" class="form">
    <h6>Send us an e-mail</h6>
    <div class="form__input-wrapper">
      <input
        class="form__input"
        type="text"
        placeholder="Your name"
        v-model="name"
      />
      <input
        class="form__input"
        type="email"
        placeholder="Your email"
        v-model="email"
      />
    </div>
    <input
      class="form__input"
      type="text"
      placeholder="Subject"
      v-model="subject"
    />
    <textarea
      class="form__input-message"
      name=""
      id=""
      cols="30"
      rows="10"
      placeholder="Message"
      v-model="message"
    ></textarea>
    <button
      class="form__button"
      :class="{ disabled: isFormInvalid }"
      type="submit"
    >
      SEND
    </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
    }
  },
  computed: {
    isFormInvalid() {
      const { name, email, subject, message } = this
      return !name || !email || !subject || !message
    },
  },
  methods: {
    sendForm() {
      if (this.isFormInvalid) {
        return
      }
      this.$emit('sendForm')
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  h6 {
    margin-bottom: 14px;
  }
}
</style>
