<template>
  <main class="FAQ">
    <div class="container">
      <div>
        <h3 class="FAQ__title">Help support</h3>
        <p class="FAQ__subtitle">
          <router-link :to="{ name: 'Store home' }">Home </router-link>
          >
          <span>FAQ</span>
        </p>
      </div>
      <div class="tabs">
        <transition name="side-fade" mode="out-in">
          <div v-if="tab === 0" class="tabs__kontent" key="content-1">
            <v-expansion-panels
              v-model="expansion"
              accordion
              flat
              class="aboutUs__accordion"
            >
              <v-expansion-panel
                v-for="(i, index) in 2"
                :key="i"
                class="aboutUs__accordion-item"
                :class="{ 'border-bottom': index !== 1 }"
              >
                <v-expansion-panel-header
                  color="#f8f8f8"
                  hide-actions
                  class="accordion-btn"
                >
                  <span
                    class="accordion-icon"
                    :style="
                      index === expansion
                        ? 'background-image: url(/img/icons/minus-icon.svg)'
                        : 'background-image: url(/img/icons/plus-icon.svg)'
                    "
                  ></span>
                  <h2>How to place an order?</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  color="#f8f8f8"
                  class="accordion-content"
                >
                  <p>
                    <span class="accordion-content-subtitle">
                      Creating quality items for everyday
                    </span>
                    We believe that everyone deserves quality. Durable use,
                    which meets the need, is what makes us happy. In doing so,
                    we rely on professional expertise and creative excellence in
                    working with felt.
                  </p>

                  <p>
                    TUMAR is a producer and design group. Our first product was
                    launched in 1998 by three people. Today we are proud to
                    empower 200 individuals of professional felt makers and
                    designers. We serve thousands of customers around the world
                    by making 100% natural wool products.
                  </p>
                  <p>
                    We draw ideas from traditional materials and craft
                    traditions of nomadic ancestors. We are inspired by the art
                    of transforming creative minds into high quality and
                    functional items of everyday. Our mission is to engage with
                    the world by modernizing felt and elevating standards of
                    living for customers and ourselves.
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div v-if="tab === 1" class="tabs__kontent" key="content-2">
            <v-expansion-panels
              v-model="expansion"
              accordion
              flat
              class="aboutUs__accordion"
            >
              <v-expansion-panel
                class="aboutUs__accordion-item"
                :class="{ 'border-bottom': index !== 0 }"
                v-for="(i, index) in 1"
                :key="i"
              >
                <v-expansion-panel-header
                  color="#f8f8f8"
                  hide-actions
                  class="accordion-btn"
                >
                  <span
                    class="accordion-icon"
                    :style="
                      index === expansion
                        ? 'background-image: url(/img/icons/minus-icon.svg)'
                        : 'background-image: url(/img/icons/plus-icon.svg)'
                    "
                  ></span>
                  <h2>How to place an order?</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  color="#f8f8f8"
                  class="accordion-content"
                >
                  <p>
                    <span class="accordion-content-subtitle">
                      Creating quality items for everyday
                    </span>
                    We believe that everyone deserves quality. Durable use,
                    which meets the need, is what makes us happy. In doing so,
                    we rely on professional expertise and creative excellence in
                    working with felt.
                  </p>

                  <p>
                    TUMAR is a producer and design group. Our first product was
                    launched in 1998 by three people. Today we are proud to
                    empower 200 individuals of professional felt makers and
                    designers. We serve thousands of customers around the world
                    by making 100% natural wool products.
                  </p>
                  <p>
                    We draw ideas from traditional materials and craft
                    traditions of nomadic ancestors. We are inspired by the art
                    of transforming creative minds into high quality and
                    functional items of everyday. Our mission is to engage with
                    the world by modernizing felt and elevating standards of
                    living for customers and ourselves.
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div v-if="tab === 2" class="tabs__kontent" key="content-3">
            <v-expansion-panels
              v-model="expansion"
              accordion
              flat
              class="aboutUs__accordion"
            >
              <v-expansion-panel
                v-for="(i, index) in 2"
                :key="i"
                class="aboutUs__accordion-item"
                :class="{ 'border-bottom': index !== 1 }"
              >
                <v-expansion-panel-header
                  color="#f8f8f8"
                  hide-actions
                  class="accordion-btn"
                >
                  <span
                    class="accordion-icon"
                    :style="
                      index === expansion
                        ? 'background-image: url(/img/icons/minus-icon.svg)'
                        : 'background-image: url(/img/icons/plus-icon.svg)'
                    "
                  ></span>
                  <h2>How to place an order?</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  color="#f8f8f8"
                  class="accordion-content"
                >
                  <p>
                    <span class="accordion-content-subtitle">
                      Creating quality items for everyday
                    </span>
                    We believe that everyone deserves quality. Durable use,
                    which meets the need, is what makes us happy. In doing so,
                    we rely on professional expertise and creative excellence in
                    working with felt.
                  </p>

                  <p>
                    TUMAR is a producer and design group. Our first product was
                    launched in 1998 by three people. Today we are proud to
                    empower 200 individuals of professional felt makers and
                    designers. We serve thousands of customers around the world
                    by making 100% natural wool products.
                  </p>
                  <p>
                    We draw ideas from traditional materials and craft
                    traditions of nomadic ancestors. We are inspired by the art
                    of transforming creative minds into high quality and
                    functional items of everyday. Our mission is to engage with
                    the world by modernizing felt and elevating standards of
                    living for customers and ourselves.
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div v-if="tab === 3" class="tabs__kontent" key="content-4">
            <v-expansion-panels
              v-model="expansion"
              accordion
              flat
              class="aboutUs__accordion"
            >
              <v-expansion-panel
                v-for="(i, index) in 2"
                :key="i"
                class="aboutUs__accordion-item"
                :class="{ 'border-bottom': index !== 1 }"
              >
                <v-expansion-panel-header
                  color="#f8f8f8"
                  hide-actions
                  class="accordion-btn"
                >
                  <span
                    class="accordion-icon"
                    :style="
                      index === expansion
                        ? 'background-image: url(/img/icons/minus-icon.svg)'
                        : 'background-image: url(/img/icons/plus-icon.svg)'
                    "
                  ></span>
                  <h2>How to place an order?</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  color="#f8f8f8"
                  class="accordion-content"
                >
                  <p>
                    <span class="accordion-content-subtitle">
                      Creating quality items for everyday
                    </span>
                    We believe that everyone deserves quality. Durable use,
                    which meets the need, is what makes us happy. In doing so,
                    we rely on professional expertise and creative excellence in
                    working with felt.
                  </p>

                  <p>
                    TUMAR is a producer and design group. Our first product was
                    launched in 1998 by three people. Today we are proud to
                    empower 200 individuals of professional felt makers and
                    designers. We serve thousands of customers around the world
                    by making 100% natural wool products.
                  </p>
                  <p>
                    We draw ideas from traditional materials and craft
                    traditions of nomadic ancestors. We are inspired by the art
                    of transforming creative minds into high quality and
                    functional items of everyday. Our mission is to engage with
                    the world by modernizing felt and elevating standards of
                    living for customers and ourselves.
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div
            v-if="tab === 4"
            class="tabs__kontent form-wrapper"
            id="content-5"
          >
            <SendEmailForm @sendForm="sendForm" />
            <img src="/img/map.jpg" alt="map.jpg" />
          </div>
        </transition>

        <div
          class="tabs__links"
          :style="$vuetify.breakpoint.smAndDown ? 'overflow: auto;' : ''"
        >
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''"
            :class="{
              active: tab === 0,
              'active-mobile': $vuetify.breakpoint.smAndDown && tab === 0,
              'd-flex justify-center': $vuetify.breakpoint.smAndDown,
            }"
            @click="tab = 0"
          >
            <div class="tabs__links-img">
              <img
                src="/img/productInfo.png"
                alt="productInfo.png"
                width="68"
                height="25"
              />
            </div>
            <p>Product<br />information</p>
          </div>
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''"
            :class="{
              active: tab === 1,
              'active-mobile': $vuetify.breakpoint.smAndDown && tab === 1,
              'd-flex justify-center': $vuetify.breakpoint.smAndDown,
            }"
            @click="tab = 1"
          >
            <div class="tabs__links-img">
              <img
                src="/img/checkout.png"
                alt="checkout.png"
                width="81"
                height="51"
              />
            </div>
            <p>Checkout</p>
          </div>
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''"
            :class="{
              active: tab === 2,
              'active-mobile': $vuetify.breakpoint.smAndDown && tab === 2,
              'd-flex justify-center': $vuetify.breakpoint.smAndDown,
            }"
            @click="tab = 2"
          >
            <div class="tabs__links-img">
              <img
                src="/img/delivery.png"
                alt="delivery.png"
                width="67"
                height="61"
              />
            </div>
            <p>Delivery</p>
          </div>
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''"
            :class="{
              active: tab === 3,
              'active-mobile': $vuetify.breakpoint.smAndDown && tab === 3,
              'd-flex justify-center': $vuetify.breakpoint.smAndDown,
            }"
            @click="tab = 3"
          >
            <div class="tabs__links-img">
              <img
                src="/img/return.png"
                alt="return.png"
                width="40"
                height="51"
              />
            </div>
            <p>Return<br />policy</p>
          </div>
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''"
            :class="{
              'active active--contact': tab === 4,
              'd-flex justify-center mr-0 px-3': $vuetify.breakpoint.smAndDown,
            }"
            @click="tab = 4"
          >
            <div
              class="tabs__links-img"
              :class="{ 'd-none': $vuetify.breakpoint.smAndDown }"
            ></div>
            <p class="visible">Contact us</p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SendEmailForm from '@/view/content/store/SendEmailForm'
export default {
  name: 'FAQComponent',
  components: {
    SendEmailForm,
  },
  data() {
    return {
      tab: 0,
      expansion: null,
    }
  },
  computed: {
    isFormInvalid() {
      const { name, email, subject, message } = this.contactUs
      return !name || !email || !subject || !message
    },
  },
  methods: {
    sendForm() {
      // send form
    },
  },
}
</script>

<style scoped lang="scss">
.v-expansion-panel--active > .v-expansion-panel-header {
  h2 {
    transition: 0.4s;
    color: #423f3d;
  }
}
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: unset;
}
.tabs__links .active {
  background-color: #f8f8f8;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    transform: translateX(100%);
    bottom: -1px;
    width: 32px;
    background-color: #f8f8f8;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #f2f2f2;
  }
  &.active--contact:after {
    content: none;
  }
  &.active-mobile:after {
    content: none;
  }
}
.form-wrapper {
  background-color: #fff;
}
.accordion-content p {
  font-size: 12px;
}
.FAQ__subtitle {
  span {
    color: rgba(173, 173, 173, 1);
  }
}
.border-bottom {
  border-bottom: 1px solid #f2f2f2;
}
</style>
