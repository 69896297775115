var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"FAQ"},[_c('div',{staticClass:"container"},[_c('div',[_c('h3',{staticClass:"FAQ__title"},[_vm._v("Help support")]),_c('p',{staticClass:"FAQ__subtitle"},[_c('router-link',{attrs:{"to":{ name: 'Store home' }}},[_vm._v("Home ")]),_vm._v(" > "),_c('span',[_vm._v("FAQ")])],1)]),_c('div',{staticClass:"tabs"},[_c('transition',{attrs:{"name":"side-fade","mode":"out-in"}},[(_vm.tab === 0)?_c('div',{key:"content-1",staticClass:"tabs__kontent"},[_c('v-expansion-panels',{staticClass:"aboutUs__accordion",attrs:{"accordion":"","flat":""},model:{value:(_vm.expansion),callback:function ($$v) {_vm.expansion=$$v},expression:"expansion"}},_vm._l((2),function(i,index){return _c('v-expansion-panel',{key:i,staticClass:"aboutUs__accordion-item",class:{ 'border-bottom': index !== 1 }},[_c('v-expansion-panel-header',{staticClass:"accordion-btn",attrs:{"color":"#f8f8f8","hide-actions":""}},[_c('span',{staticClass:"accordion-icon",style:(index === _vm.expansion
                      ? 'background-image: url(/img/icons/minus-icon.svg)'
                      : 'background-image: url(/img/icons/plus-icon.svg)')}),_c('h2',[_vm._v("How to place an order?")])]),_c('v-expansion-panel-content',{staticClass:"accordion-content",attrs:{"color":"#f8f8f8"}},[_c('p',[_c('span',{staticClass:"accordion-content-subtitle"},[_vm._v(" Creating quality items for everyday ")]),_vm._v(" We believe that everyone deserves quality. Durable use, which meets the need, is what makes us happy. In doing so, we rely on professional expertise and creative excellence in working with felt. ")]),_c('p',[_vm._v(" TUMAR is a producer and design group. Our first product was launched in 1998 by three people. Today we are proud to empower 200 individuals of professional felt makers and designers. We serve thousands of customers around the world by making 100% natural wool products. ")]),_c('p',[_vm._v(" We draw ideas from traditional materials and craft traditions of nomadic ancestors. We are inspired by the art of transforming creative minds into high quality and functional items of everyday. Our mission is to engage with the world by modernizing felt and elevating standards of living for customers and ourselves. ")])])],1)}),1)],1):_vm._e(),(_vm.tab === 1)?_c('div',{key:"content-2",staticClass:"tabs__kontent"},[_c('v-expansion-panels',{staticClass:"aboutUs__accordion",attrs:{"accordion":"","flat":""},model:{value:(_vm.expansion),callback:function ($$v) {_vm.expansion=$$v},expression:"expansion"}},_vm._l((1),function(i,index){return _c('v-expansion-panel',{key:i,staticClass:"aboutUs__accordion-item",class:{ 'border-bottom': index !== 0 }},[_c('v-expansion-panel-header',{staticClass:"accordion-btn",attrs:{"color":"#f8f8f8","hide-actions":""}},[_c('span',{staticClass:"accordion-icon",style:(index === _vm.expansion
                      ? 'background-image: url(/img/icons/minus-icon.svg)'
                      : 'background-image: url(/img/icons/plus-icon.svg)')}),_c('h2',[_vm._v("How to place an order?")])]),_c('v-expansion-panel-content',{staticClass:"accordion-content",attrs:{"color":"#f8f8f8"}},[_c('p',[_c('span',{staticClass:"accordion-content-subtitle"},[_vm._v(" Creating quality items for everyday ")]),_vm._v(" We believe that everyone deserves quality. Durable use, which meets the need, is what makes us happy. In doing so, we rely on professional expertise and creative excellence in working with felt. ")]),_c('p',[_vm._v(" TUMAR is a producer and design group. Our first product was launched in 1998 by three people. Today we are proud to empower 200 individuals of professional felt makers and designers. We serve thousands of customers around the world by making 100% natural wool products. ")]),_c('p',[_vm._v(" We draw ideas from traditional materials and craft traditions of nomadic ancestors. We are inspired by the art of transforming creative minds into high quality and functional items of everyday. Our mission is to engage with the world by modernizing felt and elevating standards of living for customers and ourselves. ")])])],1)}),1)],1):_vm._e(),(_vm.tab === 2)?_c('div',{key:"content-3",staticClass:"tabs__kontent"},[_c('v-expansion-panels',{staticClass:"aboutUs__accordion",attrs:{"accordion":"","flat":""},model:{value:(_vm.expansion),callback:function ($$v) {_vm.expansion=$$v},expression:"expansion"}},_vm._l((2),function(i,index){return _c('v-expansion-panel',{key:i,staticClass:"aboutUs__accordion-item",class:{ 'border-bottom': index !== 1 }},[_c('v-expansion-panel-header',{staticClass:"accordion-btn",attrs:{"color":"#f8f8f8","hide-actions":""}},[_c('span',{staticClass:"accordion-icon",style:(index === _vm.expansion
                      ? 'background-image: url(/img/icons/minus-icon.svg)'
                      : 'background-image: url(/img/icons/plus-icon.svg)')}),_c('h2',[_vm._v("How to place an order?")])]),_c('v-expansion-panel-content',{staticClass:"accordion-content",attrs:{"color":"#f8f8f8"}},[_c('p',[_c('span',{staticClass:"accordion-content-subtitle"},[_vm._v(" Creating quality items for everyday ")]),_vm._v(" We believe that everyone deserves quality. Durable use, which meets the need, is what makes us happy. In doing so, we rely on professional expertise and creative excellence in working with felt. ")]),_c('p',[_vm._v(" TUMAR is a producer and design group. Our first product was launched in 1998 by three people. Today we are proud to empower 200 individuals of professional felt makers and designers. We serve thousands of customers around the world by making 100% natural wool products. ")]),_c('p',[_vm._v(" We draw ideas from traditional materials and craft traditions of nomadic ancestors. We are inspired by the art of transforming creative minds into high quality and functional items of everyday. Our mission is to engage with the world by modernizing felt and elevating standards of living for customers and ourselves. ")])])],1)}),1)],1):_vm._e(),(_vm.tab === 3)?_c('div',{key:"content-4",staticClass:"tabs__kontent"},[_c('v-expansion-panels',{staticClass:"aboutUs__accordion",attrs:{"accordion":"","flat":""},model:{value:(_vm.expansion),callback:function ($$v) {_vm.expansion=$$v},expression:"expansion"}},_vm._l((2),function(i,index){return _c('v-expansion-panel',{key:i,staticClass:"aboutUs__accordion-item",class:{ 'border-bottom': index !== 1 }},[_c('v-expansion-panel-header',{staticClass:"accordion-btn",attrs:{"color":"#f8f8f8","hide-actions":""}},[_c('span',{staticClass:"accordion-icon",style:(index === _vm.expansion
                      ? 'background-image: url(/img/icons/minus-icon.svg)'
                      : 'background-image: url(/img/icons/plus-icon.svg)')}),_c('h2',[_vm._v("How to place an order?")])]),_c('v-expansion-panel-content',{staticClass:"accordion-content",attrs:{"color":"#f8f8f8"}},[_c('p',[_c('span',{staticClass:"accordion-content-subtitle"},[_vm._v(" Creating quality items for everyday ")]),_vm._v(" We believe that everyone deserves quality. Durable use, which meets the need, is what makes us happy. In doing so, we rely on professional expertise and creative excellence in working with felt. ")]),_c('p',[_vm._v(" TUMAR is a producer and design group. Our first product was launched in 1998 by three people. Today we are proud to empower 200 individuals of professional felt makers and designers. We serve thousands of customers around the world by making 100% natural wool products. ")]),_c('p',[_vm._v(" We draw ideas from traditional materials and craft traditions of nomadic ancestors. We are inspired by the art of transforming creative minds into high quality and functional items of everyday. Our mission is to engage with the world by modernizing felt and elevating standards of living for customers and ourselves. ")])])],1)}),1)],1):_vm._e(),(_vm.tab === 4)?_c('div',{staticClass:"tabs__kontent form-wrapper",attrs:{"id":"content-5"}},[_c('SendEmailForm',{on:{"sendForm":_vm.sendForm}}),_c('img',{attrs:{"src":"/img/map.jpg","alt":"map.jpg"}})],1):_vm._e()]),_c('div',{staticClass:"tabs__links",style:(_vm.$vuetify.breakpoint.smAndDown ? 'overflow: auto;' : '')},[_c('div',{class:{
            active: _vm.tab === 0,
            'active-mobile': _vm.$vuetify.breakpoint.smAndDown && _vm.tab === 0,
            'd-flex justify-center': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''),on:{"click":function($event){_vm.tab = 0}}},[_vm._m(0),_vm._m(1)]),_c('div',{class:{
            active: _vm.tab === 1,
            'active-mobile': _vm.$vuetify.breakpoint.smAndDown && _vm.tab === 1,
            'd-flex justify-center': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''),on:{"click":function($event){_vm.tab = 1}}},[_vm._m(2),_c('p',[_vm._v("Checkout")])]),_c('div',{class:{
            active: _vm.tab === 2,
            'active-mobile': _vm.$vuetify.breakpoint.smAndDown && _vm.tab === 2,
            'd-flex justify-center': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''),on:{"click":function($event){_vm.tab = 2}}},[_vm._m(3),_c('p',[_vm._v("Delivery")])]),_c('div',{class:{
            active: _vm.tab === 3,
            'active-mobile': _vm.$vuetify.breakpoint.smAndDown && _vm.tab === 3,
            'd-flex justify-center': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''),on:{"click":function($event){_vm.tab = 3}}},[_vm._m(4),_vm._m(5)]),_c('div',{class:{
            'active active--contact': _vm.tab === 4,
            'd-flex justify-center mr-0 px-3': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''),on:{"click":function($event){_vm.tab = 4}}},[_c('div',{staticClass:"tabs__links-img",class:{ 'd-none': _vm.$vuetify.breakpoint.smAndDown }}),_c('p',{staticClass:"visible"},[_vm._v("Contact us")])])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs__links-img"},[_c('img',{attrs:{"src":"/img/productInfo.png","alt":"productInfo.png","width":"68","height":"25"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Product"),_c('br'),_vm._v("information")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs__links-img"},[_c('img',{attrs:{"src":"/img/checkout.png","alt":"checkout.png","width":"81","height":"51"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs__links-img"},[_c('img',{attrs:{"src":"/img/delivery.png","alt":"delivery.png","width":"67","height":"61"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs__links-img"},[_c('img',{attrs:{"src":"/img/return.png","alt":"return.png","width":"40","height":"51"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Return"),_c('br'),_vm._v("policy")])
}]

export { render, staticRenderFns }